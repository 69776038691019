import Vue from 'vue'
import VueRouter from 'vue-router'

import VueAnalytics from 'vue-analytics'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'

// Get cookies info
import cookies from '@/plugins/appzmudri@component/cookie-banner/getCookie'

export default {
	// addGoogleAnalytics(router: VueRouter, config=[]) {
	// 	if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID)
	// 		Vue.use(VueAnalytics, {
	// 			id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
	// 			router,
	// 			set: config
	// 		})
	// },
	// addFacebookPixel(router: VueRouter) {
	// 	if (process.env.VUE_APP_FACEBOOK_PIXEL_ID && cookies.getCookie()[Object.keys(cookies.getCookie())[3]]) {
	// 		Vue.use(VueFacebookPixel, {
	// 			id: process.env.VUE_APP_FACEBOOK_PIXEL_ID,
	// 			router
	// 		})
	// 	}
	// }   
}