export default {
	CONFIG: {},
	FEATURE: {
		lessonDownloadLink: false,
		lessonHeroLink: false,
		lessonSkill: false,
		lessonStars: false,
		lessonPlaylists: false,
		lessonShare: true,
		lessonQuestions: true,
	}
}