export default {
	FEATURE: {
	},
	CONFIG: {
		profileLinks: {
			teacher: {
				userManual: {
					title: 'Pomocník',
					url: '/pomocnik',
					icon: () => import('@/plugins/appzmudri/_theme/icon/user-manual.svg?inline'),
					iconColor: 'purple-user-manual'
				},
				classes: {
					title: 'Triedy',
					url: '/triedy',
					icon: () => import('@/plugins/appzmudri/_theme/icon/groups.svg?inline'),
					iconColor: 'orange-classes'
				},
				// tasks: {
				// 	title: 'Moje úlohy',
				// 	url: '/moje-ulohy',
				// 	icon: () => import('@/plugins/appzmudri/_theme/icon/checked-circle.svg?inline'),
				// 	iconColor: 'green'
				// },
				diploms: {
					title: 'Moje diplomy',
					url: '/diplomy',
					icon: () => import('@/plugins/appzmudri/_theme/icon/ribbon.svg?inline'),
					iconColor: 'orange-diploms'
				},
				profile: {
					title: 'Moje údaje',
					url: '/profil',
					icon: () => import('@/plugins/appzmudri/_theme/icon/user.svg?inline'),
					iconColor: 'primary'
				},
				favoriteCourses: {
					title: 'Moje kurzy a série',
					url: '/oblubene-kurzy',
					icon: () => import('@/plugins/appzmudri/_theme/icon/heart.svg?inline'),
					iconColor: 'secondary'
				}
			},
			student: {
				classes: {
					title: 'Triedy',
					url: '/triedy',
					icon: () => import('@/plugins/appzmudri/_theme/icon/groups.svg?inline'),
					iconColor: 'orange-classes'
				},
				diploms: {
					title: 'Diplomy',
					url: '/diplomy',
					icon: () => import('@/plugins/appzmudri/_theme/icon/ribbon.svg?inline'),
					iconColor: 'orange-diploms'
				},
				profile: {
					title: 'Moje údaje',
					url: '/profil',
					icon: () => import('@/plugins/appzmudri/_theme/icon/user.svg?inline'),
					iconColor: 'primary'
				},
				favoriteCourses: {
					title: 'Moje kurzy a série',
					url: '/oblubene-kurzy',
					icon: () => import('@/plugins/appzmudri/_theme/icon/heart.svg?inline'),
					iconColor: 'secondary'
				},
				// tasks: {
				// 	title: 'Moje úlohy',
				// 	url: '/moje-ulohy',
				// 	icon: () => import('@/plugins/appzmudri/_theme/icon/checked-circle.svg?inline'),
				// 	iconColor: 'green'
				// }
			}
		},
	},
}