import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from './router'

import '@/custom.shared'
import './theme.scss'

// config
import wConfig from '@/plugins/w@cli/featureconfig/index'
import defaultConfig from '@/config/default'
import customConfig from '@/plugins/appzmudri/_config/config'

wConfig.addConfigAndFeatureVariables(customConfig, defaultConfig)

// globalne komponenty
Vue.component('z-course-card', () => import (`@/plugins/appzmudri@component/course-card/z-course-card.vue`))

//logger
import { wEvent } from '@/plugins/w/event'
import '@/plugins/w/event/activityTracker.service'
Vue.prototype.$wEvent = wEvent


// translate
import i18n from '@/plugins/w/translate'
import Translations from '@/plugins/w/translate/translations'

Translations.setup('sk', { //default language
	sk: 'Slovak',
	en: 'English',
})

// import WAnalytics from '@/plugins/w@integration/analytics2.0/index'
// WAnalytics.addGoogleAnalytics(router)
// WAnalytics.addGoogleTagManager(router)

// import ZmudriHotjar from '@/plugins/appzmudri@integration/hotjar/index'
// ZmudriHotjar.addHotjar()

// Vue init
new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')

Vue.prototype.EventBus = new Vue()

import moxios from '@/plugins/w/axios-mocks'
localStorage.setItem('isMoxios', 'false')

moxios.mock({
	// 'GET v1/group-admin/groups/(gid)/members/(mid)/tasks': true,
	// 'GET v1/group-admin/groups/(gid)/tasks/(tid)': true,
	// 'GET v1/group-admin/groups/(gid)/tasks': true,
	// 'GET v1/group-admin/groups/(gid)': true,
	// 'GET v1/group-admin/groups': true,
	// 'POST v1/group-admin/groups/(gid)/members/(mid)/accept':true,
	// 'POST v1/group-admin/groups/(gid)/members/(mid)/reject':true,
	// 'POST v1/group-admin/groups/(gid)/members/(mid)/reactivate':true,
	// 'POST v1/group-admin/groups/(gid)/mass-invite': true,
	// 'POST v1/group-admin/groups': true,
	// 'POST v1/group-admin/groups/(gid)/tasks': true,
	// 'PATCH v1/group-admin/groups/(gid)/tasks/(tid)': true,
	// 'PATCH v1/group-admin/groups/(gid)': true,
	// 'DELETE v1/group-admin/groups/(gid)/tasks/(tid)': true,
	// 'DELETE v1/group-admin/groups/(gid)/members/(mid)': true,
	// 'DELETE v1/group-admin/groups/(gid)': true,

	// 'GET v1/group-member/groups/(gid)/tasks': true,
	// 'GET v1/group-member/groups/(gid)': true,
	// 'GET v1/group-member/groups': true,
	// 'POST v1/group-member/groups/(gid)/leave': true,
	// 'POST v1/group-member/groups/link-join/(hash)': true,
	//'GET user/courses': true,
	// 'GET v1/courses/(slug)' : true,
	'GET v1/courses': true,
	'GET path': true,
	'GET v1/certificates': true,
	'GET lessons/multiple': true,
	// 'GET v1/enum/theme': true,
	// 'GET v1/enum/subject': true,
	'POST v1/logger/feevent': true,
	// 'POST userflag': true,

	//Needs to be generated
	// 'GET v1/auth/info': true,
	// 'POST v1/auth/info': true,
	// 'POST v1/auth/invalidate': true,
	// 'POST v1/auth/login': true,
	// 'POST v1/auth/signup': true,

}, 'zmudri/')