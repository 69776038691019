import Vue from 'vue'
import lodash from 'lodash'

export default {
	addConfigAndFeatureVariables(customConfig, defaultConfig={}) {
		const config = lodash.merge(defaultConfig, customConfig)

		Vue.prototype.CONFIG = config.CONFIG || null
		Vue.prototype.FEATURE = config.FEATURE || null
	}
}