import Vue from 'vue'

// Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Styles
import '@/plugins/w/universalcss/bootstrap.sass'
import '@/plugins/lib/_theme/index.scss'

// global components
Vue.component('w-loadable', () => import ('@/plugins/w/loadable/w-loadable.vue'))
Vue.component('z-card', () => import ('@/plugins/lib@_component/card/z-card.vue'))
Vue.component('z-cards', () => import(`@/plugins/lib@_component/cards/z-cards.vue`))

// Vue-events
import VueEvents from 'vue-events'
Vue.use(VueEvents)

// Vue-paginate
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

// Vue-truncate
import VueTruncate from 'vue-truncate-filter'
Vue.use(VueTruncate)

// // translate
// import i18n from '@/plugins/w/translate'
// import Translations from '@/plugins/w/translate/translations'

// Translations.setup('sk', { //default language
// 	sk: 'Slovak',
// 	en: 'English',
// })

// setTimeout(() => {
// 	Translations.setLocale('sk')
// }, 200)

// W/Toast
import wToast from '@/plugins/w/toast'
// TODO: how to use specific config for globally added plugins per app?
// import global config here and override in app main.ts?
Vue.use(wToast, { duration: 3000 })

// DEV
import '@/plugins/w/devmini'

// Favicon
import setFavicon from '@/plugins/w@multi/favicon'
setFavicon(`favicon-${process.env.VUE_APP_PLATFORM}.png`)

// turn off production tip
Vue.config.productionTip = false
