import i18n from  '@/plugins/w/translate'

export default {
	parseToErrors(data) {
		let errorsArray = this.parseErrorsToArrayOfStrings(data)
		
		return errorsArray.map(error => i18n.t(error))
	},
	
	parseErrorsToArrayOfStrings(data) {
		if (typeof data == 'string') {
			return [data]
		}
		
		if (data && data.error && typeof data.error == 'string') {
			return [data.error]
		}
		
		if (data && data.error && typeof data.error == 'object') {
			return Object.values(data.error)
		}
		
		if (data.data && data.data.error && typeof data.data.error == 'string') {
			return [data.data.error]
		}
		
		if (data && data.data && data.data.error && typeof data.data.error == 'object') {
			return Object.values(data.data.error)
		}
		
		if (data.statusText) {
			return [data.statusText]
		}

		return [data]
	}
}