import axios from 'axios'
import store from '@/store'

let isRefreshing = false
let refreshHook = Promise.resolve(null)

async function _unauthorised(error) {
	try {
		if (!error.response || error.response.status != 401)
			throw error

		if (!isRefreshing) {
			refreshHook = store.dispatch('wAuth/refreshToken').finally(() => isRefreshing = false)
			isRefreshing = true
		}

		const token = await refreshHook
		if (!token) {
			this.$wEvent.userLog('user.logout', {timestamp: new Date()})
			store.dispatch('wAuth/logout')
			throw 'Token not refreshed!'
		}

		error.config.headers = {
			'Authorization': `bearer ${token}`
		}
		if (error.config.method.toLocaleLowerCase() == 'post' || error.config.method.toLocaleLowerCase() == 'put') {
			error.config.data = JSON.parse(error.config.data)
		}
		return await axios.request(error.config)
	} catch (err) {
		return Promise.reject(error)
	}
}

axios.interceptors.response.use(
	(response) => response,
	(error) => _unauthorised(error)
)
