export default {
	FEATURE: {
		coursesNumberOfResults: true,
		courseSearchBar: true,
		courseSearchBarEnter: true,
		coursesCategories: true,
		coursesStars: true
	},
	CONFIG: {
		courseSearch: {
			minLength: 3,
			pageNameAfterEnter: 'Kurzy'
		}
	},
}