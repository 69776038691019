export default {
	CONFIG: {
		
	},
	FEATURE: {
		userName: true,
		userSurname: true,
		userEmail: true,
		userUsername: false,
		userPhoto: false,
		userSchool: false,
	}
}