export default {
	CONFIG: {
		courseSearch: {
			minLength: 3
		}
	},
	FEATURE: {
		coursesThemes: false,
		coursesNumberOfResults: false,
		courseSearchBar: true,
		courseSearchBarEnter: true,
		coursesCategories: false,
		coursesStars: false,
		coursesTags: true,
		coursesAuthor: true,
	}
}