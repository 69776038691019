import wAxios from '@/plugins/w/axios'
import store from '@/store'

const api = {
	log: (payload) => wAxios.post_auth(`v1/logger/feevent`, payload)
}

export const wEvent = {
	//log regardless of user state (if user is present log him too)
	log: (eventName, payload = {}) => {
		const loggedEventObject = {event_type: eventName, payload: payload}
		const eventWithKey = {secret: process.env.VUE_APP_IMPORTS_SECRET, user_id: store.getters['wAuth/user']?.id || null,  ...loggedEventObject}
	
		api.log(eventWithKey)
	},
	//log only if user is logged in
	userLog: (eventName, payload = {}) => {
		if(!store.getters['wAuth/isLoggedIn']) return

		const loggedEventObject = {event_type: eventName, payload: payload}
		const eventWithKey = {secret: process.env.VUE_APP_IMPORTS_SECRET, user_id: store.getters['wAuth/user'].id,  ...loggedEventObject}
	
		api.log(eventWithKey)
	}

	
}
