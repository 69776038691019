import profile from './profile.config'
import courses from './courses.config'
import w from './w.config'


export default {
	CONFIG: {
		profile: profile.CONFIG,
		courses: courses.CONFIG,
		w: w.CONFIG
	},
	FEATURE: {
		...courses.FEATURE,
	}
}