export default {
	CONFIG: {
		wLogin: {
			loginRoute: '/prihlasit-sa',
			routeAfterLogin: '/profil'
		},
		wChangepwd: {
			showHeading: true
		}
	},
	FEATURE: {
	}
}