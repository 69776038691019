import { wRouteTitle } from '@/plugins/w/route'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { createRouterLayout } from 'vue-router-layout'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: createRouterLayout(layout => import(`./_layouts/${layout}/${layout}.vue`)),
		children: [

			// home
			wRouteTitle('Domov',								'/',									            () => import('@/plugins/appzmudri/home/students.vue')),
			wRouteTitle('Domov učitelia',				'/pre-ucitelov',						      () => import('@/plugins/appzmudri/home/teachers.vue')),

			// static
			wRouteTitle('Podporte nás',					'/podporte-nas',						      () => import('@/plugins/appzmudri@static/support-us/support-us.vue')),
			wRouteTitle('O nás',						'/o-nas',						      			() => import('@/plugins/appzmudri/about/about.vue')),
			wRouteTitle('2% z daní',						'/daruj-2-percenta',					    () => import('@/plugins/appzmudri@static/support-us/give-2-percents.vue')),
			wRouteTitle('Partneri',						'/partneri',						      		() => import('@/plugins/appzmudri@static/partners/partners.vue')),
			// Zmudri do skol
			wRouteTitle('Do skôl',					'/zmudri-do-skol',						      () => import('@/plugins/appzmudri@static/do-skol/do-skol.vue')),

			// account
			wRouteTitle('Prihlásiť sa',					'/prihlasit-sa',									() => import('@/plugins/appzmudri/login/login.vue')),
			wRouteTitle('Registrácia',					'/registracia',										() => import('@/plugins/appzmudri/register/register.vue')),
			wRouteTitle('Zabudnuté heslo',			'/prihlasit-sa/zabudnute-heslo',	() => import('@/plugins/w@screen/forgot-pwd/forgot-pwd.vue')),
			wRouteTitle('Podmienky',						'/prihlasit-sa/podmienky',				() => import('@/plugins/w@screen/terms/terms.vue'), false, { registrationLink: '/registracia' }),
			wRouteTitle('Resetovať heslo',			'/prihlasit-sa/resetovat-heslo',	() => import('@/plugins/w@screen/reset-pwd/reset-pwd.vue')),
			wRouteTitle('Nastavenia',						'/profil/nastavenia',							() => import('@/plugins/w@screen/user-settings/user-settings.vue'), true),

			// profile features
			wRouteTitle('Profil',								'/profil',												() => import('@/plugins/appzmudri@profile/profile/profile.vue'), true),
			wRouteTitle('Obľúbené kurzy',				'/oblubene-kurzy',								() => import('@/plugins/appzmudri@profile/favourite-courses/favourite-courses.vue'), true),
			wRouteTitle('Diplomy',							'/diplomy',												() => import('@/plugins/appzmudri@profile/certificates/certificates.vue'), true),
			wRouteTitle('Pomocník',							'/pomocnik',											() => import('@/plugins/appzmudri@profile/user-manual/user-manual.vue'), true),
			wRouteTitle('Triedy',								'/triedy',												() => import('@/plugins/lib@groups/groups/groups.vue'), true),
			wRouteTitle('Trieda',								'/triedy/:id',										() => import('@/plugins/lib@groups/groups/group.vue'), true),
			wRouteTitle('Uloha',								'/triedy/:id/uloha/:taskId',			() => import('@/plugins/lib@groups/tasks/task.vue'), true),
			wRouteTitle('Študent',							'/triedy/:id/:studentId',					() => import('@/plugins/lib@groups/groups/student.vue'), true),

			// kurzy, seria, lekcia
			wRouteTitle('Kurzy',								'/kurzy',													() => import('@/plugins/lib@course/courses-with-dependent-filters/courses-with-dependent-filters.vue')),
			wRouteTitle('Séria',								'/seria/:pathSlug',								() => import('@/plugins/lib@course/path/path.vue')),
			wRouteTitle('Lekcia',								'/kurz/:courseSlug',							() => import('@/plugins/lib@lesson/lesson-tabular/lesson-tabular.vue')),

			// error not found
			wRouteTitle('404',									'/404',														() => import('@/plugins/w@screen/404/404.vue')),
			wRouteTitle('404 *',								'*',															() => import('@/plugins/w@screen/404/404.vue')),
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes,
	scrollBehavior() {
		return {x: 0, y: 0}
	}
})

router.beforeEach((to, from, next) => {
	next()
	document.title = `${to.meta.title} | Zmudri`

	if (to !== from) {
		Vue.$toast.clear()
	}
	next()
	const guardPath = (path) => {if(to.fullPath == path) localStorage.setItem('LAST_FROM_ROUTE', path )}

	const declindedPaths = ['/o-nas','/kurzy','/pre-ucitelov']

	guardPath(declindedPaths[0])
	guardPath(declindedPaths[1])
	guardPath(declindedPaths[2])
	next()

})

export default router
