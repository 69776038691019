import courses from '../_config/courses.config'
import lesson from '../_config/lesson.config'
import profile from '../_config/profile.config'
import user from '../_config/user.config'
import w from '../_config/w.config'

export default {
	CONFIG: {
		courses: courses.CONFIG,
		lesson: lesson.CONFIG,
		profile: profile.CONFIG,
		user: user.CONFIG,
		w: w.CONFIG
	},
	FEATURE: {
		...courses.FEATURE,
		...lesson.FEATURE,
		...profile.FEATURE,
		...user.FEATURE,
		...w.FEATURE
	}
}