import Vue from 'vue'
import VueToast from 'vue-toast-notification'
// import VueCompositionAPI from '@vue/composition-api'
// import { ref } from '@vue/composition-api'
import utils from './utils'
import './css/toast.css'

Vue.use(VueToast)
// Vue.use(VueCompositionAPI)

export default {
	_isLoading: false,
	_toasts: [],

	install(Vue, options) {
		this._duration = options.duration || 1000000
		Vue.prototype.$wToast = this
	},

	isLoading() {
		return this._isLoading.value
	},

	stopLoading() {
		this._isLoading.value = false
	},

	error(error='Error', id='') {
		this._isLoading.value = false
		const ID = id || this._generateToastId()

		this._toasts.value.push({
			id: ID,
			toast: Vue.$toast.open({
				message: utils.parseToErrors(error).join('<br>'),
				type: 'error',
				position: 'top',
				duration: this._duration,
				onDismiss: this.clear(ID)
			})
		})
	},

	success(message='Success', id='') {
		this._isLoading.value = false
		const ID = id || this._generateToastId()

		this._toasts.value.push({
			id: ID,
			toast: Vue.$toast.open({
				message: message,
				type: 'success',
				position: 'top',
				duration: this._duration,
				onDismiss: this.clear(ID)
			})
		})
	},

	clear_loading() {
		this._isLoading.value = true
		this.clear()
	},

	clear(id) {
		if (id) { 
			const toastIndex = this._toasts.value.findIndex(t => t.id === id)
			if (toastIndex != -1) {
				this._toasts.value[toastIndex].toast.dismiss()
				this._toasts.value.splice(toastIndex, 1)
			}
			return
		}

		this._toasts.value = []
		Vue.$toast.clear()
	},

	_generateToastId() {
		return `w-toast-${new Date().getTime()}`
	}
}
