import Vue from 'vue'
import Vuex from 'vuex'
import wAuth from '@/plugins/w@user/auth-store/wAuth.store'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		wAuth
	}
})

export default store
