export default {
	FEATURE: {
		
	},
	CONFIG: {
		wLogin: {
			routeAfterLogin: '/profil'
		},
		wChangepwd: {
			showHeading: false
		}
	},
}