<template>
	<div id="app">
		<router-view />
		<!-- <w-progressbar></w-progressbar> -->
		<cookie />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import cookie from "@/plugins/appzmudri@component/cookie-banner/cookie.vue";
export default {
	components: {
		// 'w-progressbar': () => import('@/plugins/w/progressbar/w-progressbar.vue'),
		cookie,
	},

	computed: {
		...mapGetters("wAuth", ["isLoggedIn"]),
	},

	created() {
		const location = window.location.href;
		const route = location.split("/").pop();
		if (route != "prihlasit-sa" || route != "registracia")
			localStorage.setItem("EXTERNAL_URL_CHECK", location);
	},

	watch: {
		isLoggedIn(newValue, oldValue) {
			if (!newValue && oldValue) {
				this.$router.push(this.CONFIG.w.wLogin.loginRoute || "/");
			}
		},
	},
};
</script>
<style lang="sass">
.modal-open
	overflow-y: hidden !important
html
	scroll-behavior: smooth
</style>
