export default {
	CONFIG: {
		// example configs
		profileLinks: {
			// example links
			profile: {
				// title: 'Moje údaje',
				// url: '/profil',
				// icon: () => import('@/plugins/appzmudri/_theme/icon/user.svg?inline'),
				// iconColor: 'primary'
			},
			favoriteCourses: {},
			classes: {},
			diploms: {},
			tasks: {},
			myProjects: {},
			settings: {}
		},
	},
	FEATURE: {
	}
}