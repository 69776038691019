import loginGuardIsLoggedIn from '@/plugins/w@user/auth-store/loginGuardIfMetaSet'

export const wRoute = (name: string, path: string, component, meta?, beforeEnter?) => ({name, path, component, meta, beforeEnter})

export const wRouteTitle = (title, path, component, requiresAuth = false, props?) => {
	return {
		path,
		meta: { title, requiresAuth: requiresAuth },
		component: component,
		beforeEnter: loginGuardIsLoggedIn,
		props,
		name: title
	}
}
