import {wEvent} from '@/plugins/w/event'

let lastUserActivity, userHasFocus, monitoringInterval

const afkThreshold = 60 //seconds
const logTimeInterval = 60 //seconds

window.addEventListener('mousemove', () => lastUserActivity = new Date())
window.addEventListener('keypress', () => lastUserActivity = new Date())

window.onblur = () => { 
	userHasFocus = false  
}  
window.onfocus = () => {  
	userHasFocus = true  
}

monitoringInterval = setInterval(() => {
	const now = new Date()
	const diff = (now.getTime() - lastUserActivity.getTime()) / 1000

	if((diff < afkThreshold) || userHasFocus) {
		wEvent.userLog('user.activityCheck', { timestamp: new Date() })
	}
}, logTimeInterval * 1000)
